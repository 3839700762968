import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import WowCase from '../views/WowCase.vue'
import Rates from '../views/Rates.vue'
import Cookies from '../views/Cookies.vue'
import AvisoLegal from '../views/AvisoLegal.vue'
import DecathlonCase from '../views/DecathlonCase.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },

    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited. 
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }, {
        path: '/wow',
        name: 'WowCase',
        component: WowCase
    },{
        path: '/decathlon',
        name: 'DecathlonCase',
        component: DecathlonCase
    },

   // }, {
       // path: '/rates',
       // name: 'Rates',
      //  component: Rates

    
    {path: '/cookies',
    name: 'Cookies',
    component: Cookies

    },
    {path: '/aviso-legal',
    name: 'AvisoLegal',
    component: AvisoLegal

    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router